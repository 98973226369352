<template>
    <div id="app">
		<keep-alive :include="keepAlive">
			<router-view></router-view>
		</keep-alive>
		<DisclaimerModal ref="DisclaimerModal" />
    </div>
</template>

<script>
import DisclaimerModal from '@/components/DisclaimerModal/index.vue'
export default {
    data() {
        return {}
    },
	computed: {
		keepAlive () {
			return this.$store.state.user.cacheList
		}
	},
	components: {
		DisclaimerModal
	},
	mounted() {
		let isFirst = sessionStorage.getItem('isFirst')
		if (!isFirst) {
			this.$refs['DisclaimerModal'].visible = true
			sessionStorage.setItem('isFirst', true)
		}
	}

}
</script>

<style lang="scss">
@import "assets/css/reset";
	/*滚动动画的样式*/
	.disable-hover {
		pointer-events: none;
	}
	// 鼠标动画
	@keyframes gun0 {
		0% {
		top: 6px; }
		50% {
		top: 14px; }
		100% {
		top: 6px; } 
	}
	// 新闻图动画
	@keyframes picscall{
		from {
			transform: scale(1.2);
		}
		to {
			transform: scale(1);
		}
	}
	// 旋转动画
	@keyframes roll {
		to {
			transform: rotate(360deg);
			-webkit-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
			-o-transform: rotate(360deg);
			-ms-transform: rotate(360deg);
		}
	}
	@keyframes fadeInDownSmall {
		from {
			opacity: 0;
			transform: translate3d(0%, -20px, 0);
		}
		to {
			opacity: 1;
			transform: none;
		}
	}
	@keyframes fadeInUpSmall{
		from {
			opacity: 0;
			transform: translate3d(0%, 100%,0);
		}
		to {
			opacity: 1;
			transform: none;
		}
	}
	.animated{
		-webkit-animation-duration:2s;
		animation-duration:2s;
		-webkit-animation-fill-mode:both;
		animation-fill-mode:both;
	}
	.fadeInDownSmall {
		animation-name: fadeInDownSmall;
	}
	.fadeInUpSmall{
		animation-name: fadeInUpSmall;
	}
	.picscall{
		animation-name: picscall;
	}
	.container {
		width: 100%;
		min-height: 100vh;
		background: #111111;
	}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-jc-sb {
    justify-content: space-between;
}

.ellipsis {
	overflow:hidden; 
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.two-ellipsis {
	display: -webkit-box;
	word-break: break-all;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}

.three-ellipsis {
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* 吸顶效果 */
.sticky-fixed {
    position: fixed;
    top: 0;
    z-index: 999;
}

html, body {
    width: 100%;
    height: 100%;
}

#app {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
