import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import animated from 'animate.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
import BaiduMap from 'vue-baidu-map'


Vue.use(BaiduMap, {
  ak: '7uaUD9AwZYDC15R6olNYbU6vzXQHumio'    //这个地方是官方提供的ak密钥
})
Vue.use(VueFullPage);
Vue.use(VueAwesomeSwiper);
Vue.use(animated);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
