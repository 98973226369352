<template>
  <el-dialog :visible.sync="visible" :modal="false" :show-close="false" destroy-on-close append-to-body width="800px" custom-class="login-dialog">
    <div class="dialog_box">
      <div class="close-dialog" @click="visible = false">
        <img src="@/assets/images/close_icon.png" />
      </div>
      <img src="@/assets/images/disclaimer.jpg" lazy  class="flex1" style="height: 80vh;" />
    </div>
    
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.close-dialog {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.dialog_box {
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
}
/deep/ .login-dialog{
		// position: absolute;
		// left: 50%;
		// top: 50%;
		// transform: translate(-50%,-50%);
		// margin: 0!important;
		// height: 60vh;
		margin-top: 6vh !important;
		background: linear-gradient(141deg, #2A2A33 0%, #1A1A1F 100%);
		box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.53);
		border-radius: 20px;
		border: 1px solid #3D414F;
		overflow: hidden;
		.el-dialog__header{
			display: none;
		}
		.el-dialog__body{
			// height: 100%;
			padding: 0;
			.el-input__inner{
				background: #0C0C10;
				border-radius: 4px;
				opacity: 0.9678;
				border: none;
				outline: none;
			}
		}
	}
</style>