// import { USER_AUTH,SYS_BUTTON_AUTH } from "@/store/mutation-types"
const user = {
    state: {
        token: '',
        permissionList: [],
        isLogin: false,
        userName: '',
		cacheList: []
    },
    mutations: {
        SET_USERNAME: (state, name) => {
            state.userName = name
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_PERMISSIONLIST: (state, permissionList) => {
            state.permissionList = permissionList
        },
		SET_CACHELIST: (state, cacheList) => {
			state.cacheList = cacheList
		}
    },
    actions: {
        setUserName({commit},value){
            commit('SET_USERNAME', value)
        },
        setToken({commit},value){
            commit('SET_TOKEN', value)
        },
        setPermissionList({commit},value){
            commit('SET_PERMISSIONLIST', value)
        },
		setCacheList({commit},value) {
			commit('SET_CACHELIST', value)
		}
    }
}
export default user

