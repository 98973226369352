import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('@/views/main/index'),
		redirect: '/index',
		children: [
			{
				path: '/index',
				name: 'index',
				component: () => import('@/views/index/index')
			},
			{
				path: '/news',
				name: 'news',
				component: () => import('@/views/news/index')
			},
			{
				path: '/newsDetail',
				name: 'newsDetail',
				component: () => import('@/views/news/newsDetail')
			},
			{
				path: '/moviesWorks',
				name: 'moviesWorks',
				component: () => import('@/views/moviesWorks/index')
			},
			{
				path: '/moviesDetail',
				name:'moviesDetail',
				component: () => import('@/views/moviesWorks/moviesDetail')
			},
			{
				path: '/cooperation',
				name: 'cooperation',
				component: () => import('@/views/cooperation/index')
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('@/views/about/index')
			},
			{
				path: '/personal',
				name: 'personal',
				component: () => import('@/views/user/index')
			}
		]
	}
]

const router = new VueRouter({
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})

export default router
